<template>
  <div></div>
</template>
<script>
import { login } from "@/api/api"
export default {
  data() {
    return {
    }
  },
  mounted() {
    // http://iot.hzcjkj.com/device/list?size=2&page=4&username=test
    var url = window.location.href; //获取url中"?"符后的字串       
    var cs = url.split('?')[1]; //获取?之后的参数字符串
    var cc = '';
    var cs2 = [];
    let map = new Map
    if (cs.length > 0) {
      cc = cs.split('&');
    }
    for (var i = 0; i < cc.length; i++) {
      cs2.push(cc[i].split('='))
      map.set(cs2[i][0], cs2[i][1])
    }
    // http://localhost:9000/#/casLogin?user=test&password=000 跳转链接
    if (map.get("user") == undefined || map.get("password") == undefined) {
      this.$message.error('输入参数不正确,请重新输入');
      return
    } else {
      login(decodeURIComponent(map.get("user")), map.get("password")).then(res => {
        if (res.success == false) {
          this.$message.error('用户名或密码错误');
        } else {
          sessionStorage.clear()
          if (decodeURIComponent(map.get("user")) == '洞庭湖整治工程') {
            sessionStorage['showHomeH1'] = false
          }
          sessionStorage.clear()
          sessionStorage['accountType'] = res.ClientUser.accountType
          sessionStorage['flagshow'] = false
          sessionStorage['token'] = res.token
          sessionStorage['name'] = res.ClientUser.name
          if (map.has('alias')) {
            sessionStorage['alias'] = map.get('alias')
          }
          this.setCookie(this.oid, this.orderCode, 7, res.token)
          if (!map.has("path")) {
            this.$router.push('/index');
          } else {
            this.$router.push(map.get("path"));
          }
        }
      })
    }
  },
  methods: {
    setCookie(username, password, nums, token) {
      const time = new Date()
      time.setTime(time.getTime() + 24 * 60 * 60 * 1000 * nums);
      //加上window.btoa是为了对用户名和密码进行编码，不让cookie明文展示
      window.document.cookie = `userName=${username}; path=/; expires=${time.toGMTString()}`;
      window.document.cookie = `passWord=${window.btoa(password)}; path=/; expires=${time.toGMTString()}`;
      window.document.cookie = `isChecked=${true}; path=/; expires=${time.toGMTString()}`;
      time.setTime(time.getTime() + 24 * 60 * 60 * 1000 * 15);
      window.document.cookie = `token=${token};path=/;domain=hzcjkj.com;expires=${time.toGMTString()}`
    }
  }
}
</script>
